'use client';
import React, { useState } from 'react';
import Image from 'next/image';
import { SiFacebook } from 'react-icons/si';
import { FcGoogle } from 'react-icons/fc';
import { feathersClient } from '@/components/connection/page'; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeaderPhoto from '../../../public/NewsLetterBg.png';
import { Oswald, Roboto, Fredoka } from 'next/font/google';

const fredoka = Fredoka({ subsets: ['latin'] });
const oswalds = Oswald({ subsets: ['latin'] });
const roboto = Roboto({ weight: ['300'], subsets: ['latin'] });

export const NewsLetter = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleSignUp = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevents the default form submission behavior
  
    try {
      // Check for valid email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        toast.error('Please enter a valid email address.');
        return;
      }

      // Check if both name and email are provided
      if (!name.trim() || !email.trim()) {
        
        toast.error('Please enter both name and email.');
        setName('');
        setEmail('');
        return;
        
      }
      
      // Send data to FeathersJS backend (assuming the service name is 'newsletter')
      const response = await feathersClient.service('api/v1/newsletter').create({ name, email });

      console.log('Signup response:', response);
  
      // Show success toast message to the user
      toast.success('Subscription successful!');
      setName('');
      setEmail('');
    } catch (error:any) {
      console.error('Error in subscription:', error.message);
      
      if(error.message.includes('Member Exists')) {
        toast.error('Email already subscribed.');
      }
       else{
        toast.error('An error occurred. Please try again.');
       }
    }
  };
  

    return (
        <div className="bg-cover py-[56px] bg-blue-950 flex justify-center" >
            <div className='flex flex-col justify-center items-center gap-10'>
                <div className='flex flex-col justify-center items-center max-sm:px-2'>
                    <h1 className={`${fredoka.className} text-3xl font-medium text-white`}>GET NEWS AND DEALS ON YOUR FAVOURITE EVENTS!</h1>
                    <div className='flex flex-col justify-center items-center leading-[120%] text-white'>
                        <p className={`${fredoka.className} text-lg font-normal`}>Receive the Latest News, Exclusive Content, Deals, Coupons, and</p>
                        <p className={`${fredoka.className} text-lg font-normal`}>More Directly to Your Inbox Through Our Newsletter!</p>
                    </div>
                </div>
                <form className={`flex flex-col gap-4 ${roboto.className}`}>
                <ToastContainer/>
          <div className="flex flex-col gap-2">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="h-full min-[366px]:w-[366px] w-full outline-none rounded-[31px] text-lg px-4 py-2"
              placeholder="Name"
            />
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="h-full w-full outline-none rounded-[31px] text-lg px-4 py-2"
              placeholder="Email Address"
            />
          </div>
          <button
  onClick={(e) => handleSignUp(e)}
  className="text-white bg-[#FF801F] rounded-[31px] hover:bg-[#F09855] text-lg font-bold leading-[120%] px-4 py-2"
>
  SIGN UP
</button>
        </form>

                
            </div>
        </div>
    )
}

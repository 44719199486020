"use client";
import React, {
  ChangeEvent,
  useEffect,
  useState,
  KeyboardEvent,
  useRef,
} from "react";
import { IoSearch } from "react-icons/io5";
import { useRouter } from "next/navigation";
require("dotenv").config({ path: "../.env" });
interface Search {
  id: string;
  name: string;
  slug: string;
}
interface Search {
  search: Search[];
}

let search: Search[] = [];
let url: any;
if (typeof window !== "undefined") {
  url = localStorage.getItem("Frontend_API");
  // console.log(url);
}
let domain_url: any;

if (typeof window !== "undefined") {
  domain_url = localStorage.getItem("domain_url");
  // console.log(domain_url);
}
interface SearchBarStyles {
  maindiv?: React.CSSProperties;
  searchicon?: React.CSSProperties;
  divstyle?: React.CSSProperties;
  // Add more as needed
}
interface SearchBarProps {
  styles?: SearchBarStyles;
  className?: string;
  className1?: string;
  placeholder?: string;
  setLoading?: (loading: boolean) => void;
}
export const SearchBar = ({
  className,
  className1,
  styles,
  placeholder,
  setLoading
}: SearchBarProps) => {
  const [query, setQuery] = useState("");
  const [names, setNames] = useState<Search[]>([]);
  const [searchresults, setSearchResults] = useState<Search[]>([]);
  const [selectedProductIndex, setSelectedProductIndex] = useState<number>(-1);
  const inputRef = useRef<HTMLInputElement>(null);

  const [placeholderText, setPlaceholderText] = useState('Type here to search your team, artist or venue');

  const router = useRouter();

  const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout | null = null; // Assign null as the initial value
    return (...args: any[]) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  function replaceSpaces(input: string): string {
    return input.replace(/ /g, "%20");
  }

  // const [placeholderText, setPlaceholderText] = useState('Type here to search your team, artist or venue');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) { // Adjust the breakpoint as needed
        setPlaceholderText('Type here to search for events');
      } else {
        setPlaceholderText('Type here to search events, teams, artists and venues');
      }
    };

    // Initial check
    handleResize();

    // Add event listener for resizing
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // console.log("inside useeffect query", query);
    const fetchData = async () => {
      // console.log("query", query)P
      // const input1 = replaceSpaces(query);
      const input1 = encodeURIComponent(query);
      const message =
        `GET ${domain_url}/v9/searches/suggestions?entities=events,performers,venues&limit=5&q=` +
        input1;
      // const msg1 = `GET api.sandbox.ticketevolution.com/v9/searches/suggestions?entities=events,performers,venues&limit=5&q=${input}`;
      // console.log("message", message);
      const res = await fetch(`${url}/api/signature`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(message),
      });
      if (!res.ok) {
        const errorData = await res.json(); // Attempt to parse error response
        throw new Error(
          `HTTP error! Status: ${res.status}, Error: ${errorData.error}`
        );
      }
      const data1 = await res.json();
      // console.log("data1", data1.msg);
      const requestBody = {
        // query: query,
        query: input1,
        signature: data1.msg,
      };
      const response = await fetch(`${url}/api/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      // console.log("data ---- ", data?.items)
      setNames(data?.items);
      setSearchResults(data?.items);

    };
    const debouncedFetchData = debounce(fetchData, 400);
    if (query.trim() !== "") {
      debouncedFetchData();
    }
  }, [query]);

  function handleQueryChange(event: ChangeEvent<HTMLInputElement>) {
    const updatedQuery = event.target.value;
    setQuery(updatedQuery);
    setSelectedProductIndex(-1);
    // debouncedSearch(updatedQuery);
    // setSearchResults(names);

    // console.log('query:', updatedQuery);
    // console.log("search results -- ", searchresults);
  }
  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    // console.log(event.key);
    if (event.key === "ArrowUp") {
      setSelectedProductIndex((prevIndex) =>
        prevIndex === -1 ? searchresults.length - 1 : prevIndex - 1
      );
      // console.log(selectedProductIndex);
    } else if (event.key === "ArrowDown") {
      setSelectedProductIndex((prevIndex) =>
        prevIndex === searchresults.length - 1 ? -1 : prevIndex + 1
      );
      // console.log(selectedProductIndex);
    } else if (event.key === "Enter") {
      if (selectedProductIndex !== -1) {
        const selectedProduct = searchresults[selectedProductIndex];
        handleProductClick(selectedProduct);
        setQuery("");
        setSearchResults([]);
      }
    }
  }
  function handleProductClick(name: Search) {
    // alert(`You Selected ${name.id}`)
    // console.log(name)
    setLoading?.(true);
    const type = name.id[0];
    const id = name.id.slice(1);
    if (type === "e") {
      router.push(`/viewtickets1/${id}`);
    }
    if (type === "p") {
      router.push(`/performer/${name.slug}`);
    }
    if (type === "v") {
      router.push(`/venue/${name.slug}`);
    }
    setQuery("");
    setSearchResults([]);
  }
  const scrollActiveProductIntoView = (index: number) => {
    const activeProduct = document.getElementById(`name-${index}`);
    if (activeProduct) {
      activeProduct.scrollIntoView({
        block: "nearest",
        inline: "start",
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (selectedProductIndex !== -1) {
      scrollActiveProductIntoView(selectedProductIndex);
    }
  }, [selectedProductIndex]);


  return (
    <div
      className={className}
      style={{
        ...styles?.maindiv,
        display: "-webkit-flex",
        // WebkitJustifyContent: 'center',
        // WebkitAlignItems: 'center'
      }}
    >
      <div
        className={`relative flex items-center w-full h-10 z-[1]  rounded-lg focus-within:shadow-lg  overflow-hidden max-md:h-8 `}
        style={styles?.divstyle}
      >
        <div className={`${className1}`}>
          {/* <IoSearch className={`h-6 w-6 m-3`} style={styles?.searchicon} /> */}
          <IoSearch className="h-6 w-6 m-3" style={{ color: '#27276D' }} />
        </div>

        <input
          type="text"
          className={`h-full w-full outline-none px-4 text-sm text-gray-700 max-md:text-[13px] max-md:w-[270px] max-[348px]:w-[200px] placeholder:font-bold ${placeholderText === 'Type here to search for events' ? 'placeholder:text-gray-500' : 'placeholder:text-[#27276D]'
            }`}
          placeholder={placeholderText}
          onChange={handleQueryChange}
          onKeyDown={handleKeyDown}
          value={query}
          ref={inputRef}
        />
      </div>
      <div className="bg-white max-h-96 max-w-xl rounded-lg absolute md:mt-[43px] mt-[33px] z-50">
        {query !== "" &&
          query.length > 1 &&
          searchresults?.length > 0 &&
          searchresults.map((name, index) => (
            <div key={name.id} className={` max-w-xl px-2 py-[2px]`}>
              <div
                id={`name-${index}`}
                className={`${selectedProductIndex === index ? "bg-gray-200" : ""
                  } flex max-w-xl items-center py-2 px-3 rounded-lg text-[12px] md:text-sm justify-between gap-8 hover:bg-gray-200 cursor-pointer`}
                onClick={() => handleProductClick(name)}
              >
                {name.name}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

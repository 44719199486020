"use client";
import React, { useEffect, useState } from "react";
import { feathersClient } from "./connection/page";
import { Oswald , Roboto,Fredoka} from 'next/font/google';
// import { feathersClient } from "../connection/page";
import Image from 'next/image'
import Link from 'next/link';
import LoadingAnimation from "./LoadingAnimation/LoadingAnimation";

interface Event {
  hyperLink: URL;
  _id: string;
  id:string;
  name: string;
  image_url: string;
}

const fredoka = Fredoka({ subsets: ['latin'] })
const oswalds = Oswald({ subsets: ['latin'] })
const roboto = Roboto({ weight: ['300'], subsets: ['latin'] })



// const links = [
//   {
//     id:1,
//     link : `/performer/toronto-blue-jays`
//   },
//   {
//     id:2,
//     link : `/venue/molson-canadian-amphitheatre`
//   },
//   {
//     id:3,
//     link : `/venue/air-canada-centre`
//   },
//   {
//     id:4,
//     link : `/venue/rogers-centre`
//   }
// ]

const Test = () => {
  const [data, setData] = useState<Event[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const dataResponse = await feathersClient.service('/api/v1/featured-events').find({
        //   query: {
        //     $limit: 4,
        //     $sort: {
        //       updated_at: -1
        //     }
        //   }
        // });
        const dataResponse = await feathersClient.service('/api/v1/featured-events').find({
          query: {
            $limit: 4,
            $sort: {
              updated_at: -1
            }
          }
        });

        console.log("dataResponseforFeatured",dataResponse);
        setData(dataResponse.data as Event[]);
        console.log("data56: ", data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex justify-center">
      {
        (loading) && 
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 bg-white w-screen h-screen">
        <div className='w-full h-full absolute flex items-center justify-center text-black'><LoadingAnimation/></div>
        </div>
      }
      <div className="max-w-full flex flex-col gap-1 min-[1200px]:w-[1170px] min-[922px]:w-[970px] min-md:w-[750px] max-md:px-[15px] max-md:mx-auto">
      <div className={`mb-4 text-stone-900 text-2xl md:text-3xl font-medium font-oswald uppercase leading-10 gap-2.5 ${fredoka.className}`}>
       KARMA'S TOP PICKS
      </div>
      <div className="grid grid-cols-2  lg:grid-cols-4 gap-4">
        {data.map((item, index) => (
          // <Link href={`/viewtickets1/${item.id}`}>
              <Link href={item.hyperLink ? item.hyperLink : `/viewtickets1/${item.id}`} key={index}>
          <div className={`flex flex-col justify-center items-center flex-shrink-0 md:gap-[12px] gap-[3px] ${roboto.className}  transition duration-300 hover:shadow-sm hover:scale-[1.02] cursor-pointer`} onClick={() => setLoading(true)}>
             <img src={item.image_url} alt={item.name} className="bg-slate-500 rounded-lg shrink-0 lg:w-[280px] lg:h-[180px] md:w-[270px] md:h-[152px] w-[139px] h-[90px] " />
            <div className="max-w-full">
              <p className="text-stone-500 text-base md:w-[264px] flex flex-wrap font-bold leading-tight">{item.name}</p>
            </div>
          </div>
          </Link>
        ))}
      </div>
      {/* <p>test</p> */}
    </div>
    </div>
  );
};

export default Test;


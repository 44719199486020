"use client"
import React from 'react';
import Link from 'next/link';
import { useState } from 'react';
import Image from 'next/image';
import { IoNotifications, IoMenuOutline } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { HiShoppingCart } from "react-icons/hi2";
import LoginForm from './login/page';
import { Users } from 'lucide-react';
import { cn } from "@/lib/utils"
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '@/redux/store';
import { useEffect } from 'react';
import { feathersClient } from '../components/connection/page';
import { Fredoka } from 'next/font/google';
import { usePathname, useRouter } from 'next/navigation';
import { updateCart } from '@/redux/features/cart-slice';

const fredoka = Fredoka({ weight: ['300', '400', '500', '600', '700'], subsets: ['latin'] });
// import { feathersClient } from '../app/contactus/page';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu"
const components: { title: string; href: string; description: string }[] = [
  {
    title: "Alert Dialog",
    href: "/docs/primitives/alert-dialog",
    description:
      "A modal dialog that interrupts the user with important content and expects a response.",
  },
  {
    title: "Hover Card",
    href: "/docs/primitives/hover-card",
    description:
      "For sighted users to preview content available behind a link.",
  },
  {
    title: "Progress",
    href: "/docs/primitives/progress",
    description:
      "Displays an indicator showing the completion progress of a task, typically displayed as a progress bar.",
  },
  {
    title: "Scroll-area",
    href: "/docs/primitives/scroll-area",
    description: "Visually or semantically separates content.",
  },
  {
    title: "Tabs",
    href: "/docs/primitives/tabs",
    description:
      "A set of layered sections of content—known as tab panels—that are displayed one at a time.",
  },
  {
    title: "Tooltip",
    href: "/docs/primitives/tooltip",
    description:
      "A popup that displays information related to an element when the element receives keyboard focus or the mouse hovers over it.",
  },
]


const links = [
  {
    id: 1,
    title: "mlb",
    value: "MLB",
    teams: [
      { name: "Arizona Diamondbacks", slug: "arizona-diamondbacks" },
      { name: "Atlanta Braves", slug: "atlanta-braves" },
      { name: "Baltimore Orioles", slug: "baltimore-orioles" },
      { name: "Boston Red Sox", slug: "boston-red-sox" },
      { name: "Chicago White Sox", slug: "chicago-white-sox" },
      { name: "Chicago Cubs", slug: "chicago-cubs" },
      { name: "Cincinnati Reds", slug: "cincinnati-reds" },
      { name: "Cleveland Guardians", slug: "cleveland-guardians" },
      { name: "Colorado Rockies", slug: "colorado-rockies" },
      { name: "Detroit Tigers", slug: "detroit-tigers" },
      { name: "Houston Astros", slug: "houston-astros" },
      { name: "Kansas City Royals", slug: "kansas-city-royals" },
      { name: "Los Angeles Angels", slug: "los-angeles-angels" },
      { name: "Los Angeles Dodgers", slug: "los-angeles-dodgers" },
      { name: "Miami Marlins", slug: "miami-marlins" },
      { name: "Milwaukee Brewers", slug: "milwaukee-brewers" },
      { name: "Minnesota Twins", slug: "minnesota-twins" },
      { name: "New York Yankees", slug: "new-york-yankees" },
      { name: "New York Mets", slug: "new-york-mets" },
      { name: "Oakland Athletics", slug: "oakland-athletics" },
      { name: "Philadelphia Phillies", slug: "philadelphia-phillies" },
      { name: "Pittsburgh Pirates", slug: "pittsburgh-pirates" },
      { name: "San Diego Padres", slug: "san-diego-padres" },
      { name: "San Francisco Giants", slug: "san-francisco-giants" },
      { name: "Seattle Mariners", slug: "seattle-mariners" },
      { name: "St. Louis Cardinals", slug: "st-louis-cardinals" },
      { name: "Tampa Bay Rays", slug: "tampa-bay-rays" },
      { name: "Texas Rangers", slug: "texas-rangers" },
      { name: "Toronto Blue Jays", slug: "toronto-blue-jays" },
      { name: "Washington Nationals", slug: "washington-nationals" }],
  },
  {
    id: 2,
    title: "nhl",
    value: "NHL",
    teams: [
      { name: "Anaheim Ducks", slug: "anaheim-ducks" },
      { name: "Arizona Coyotes", slug: "arizona-coyotes" },
      { name: "Boston Bruins", slug: "boston-bruins" },
      { name: "Buffalo Sabres", slug: "buffalo-sabres" },
      { name: "Calgary Flames", slug: "calgary-flames" },
      { name: "Carolina Hurricanes", slug: "carolina-hurricanes" },
      { name: "Chicago Blackhawks", slug: "chicago-blackhawks" },
      { name: "Colorado Avalanche", slug: "colorado-avalanche" },
      { name: "Columbus Blue Jackets", slug: "columbus-blue-jackets" },
      { name: "Dallas Stars", slug: "dallas-stars" },
      { name: "Detroit Red Wings", slug: "detroit-red-wings" },
      { name: "Edmonton Oilers", slug: "edmonton-oilers" },
      { name: "Florida Panthers", slug: "florida-panthers" },
      { name: "Los Angeles Kings", slug: "los-angeles-kings" },
      { name: "Minnesota Wild", slug: "minnesota-wild" },
      { name: "Montreal Canadiens", slug: "montreal-canadiens" },
      { name: "Nashville Predators", slug: "nashville-predators" },
      { name: "New Jersey Devils", slug: "new-jersey-devils" },
      { name: "New York Islanders", slug: "new-york-islanders" },
      { name: "New York Rangers", slug: "new-york-rangers" },
      { name: "Ottawa Senators", slug: "ottawa-senators" },
      { name: "Philadelphia Flyers", slug: "philadelphia-flyers" },
      { name: "Pittsburgh Penguins", slug: "pittsburgh-penguins" },
      { name: "San Jose Sharks", slug: "san-jose-sharks" },
      { name: "St. Louis Blues", slug: "st-louis-blues" },
      { name: "Tampa Bay Lightning", slug: "tampa-bay-lightning" },
      { name: "Toronto Maple Leafs", slug: "toronto-maple-leafs" },
      { name: "Vancouver Canucks", slug: "vancouver-canucks" },
      { name: "Vegas Golden Knights", slug: "golden-knights" },
      { name: "Washington Capitals", slug: "washington-capitals" },
      { name: "Winnipeg Jets", slug: "winnipeg-jets" }], // Replace with actual team names
  },
  {
    id: 3,
    title: "nba",
    value: "NBA",
    teams: [
      { name: "Atlanta Hawks", slug: "atlanta-hawks" },
      { name: "Boston Celtics", slug: "boston-celtics" },
      { name: "Brooklyn Nets", slug: "brooklyn-nets" },
      { name: "Charlotte Hornets", slug: "charlotte-hornets" },
      { name: "Chicago Bulls", slug: "chicago-bulls" },
      { name: "Cleveland Cavaliers", slug: "cleveland-cavaliers" },
      { name: "Dallas Mavericks", slug: "dallas-mavericks" },
      { name: "Denver Nuggets", slug: "denver-nuggets" },
      { name: "Detroit Pistons", slug: "detroit-pistons" },
      { name: "Golden State Warriors", slug: "golden-state-warriors" },
      { name: "Houston Rockets", slug: "houston-rockets" },
      { name: "Indiana Pacers", slug: "indiana-pacers" },
      { name: "Los Angeles Clippers", slug: "los-angeles-clippers" },
      { name: "Los Angeles Lakers", slug: "los-angeles-lakers" },
      { name: "Memphis Grizzlies", slug: "memphis-grizzlies" },
      { name: "Miami Heat", slug: "miami-heat" },
      { name: "Milwaukee Bucks", slug: "milwaukee-bucks" },
      { name: "Minnesota Timberwolves", slug: "minnesota-timberwolves" },
      { name: "New Orleans Pelicans", slug: "new-orleans-pelicans" },
      { name: "New York Knicks", slug: "new-york-knicks" },
      { name: "Oklahoma City Thunder", slug: "oklahoma-city-thunder" },
      { name: "Orlando Magic", slug: "orlando-magic" },
      { name: "Philadelphia 76ers", slug: "philadelphia-76ers" },
      { name: "Phoenix Suns", slug: "phoenix-suns" },
      { name: "Portland Trail Blazers", slug: "portland-trail-blazers" },
      { name: "Sacramento Kings", slug: "sacramento-kings" },
      { name: "San Antonio Spurs", slug: "san-antonio-spurs" },
      { name: "Toronto Raptors", slug: "toronto-raptors" },
      { name: "Utah Jazz", slug: "utah-jazz" },
      { name: "Washington Wizards", slug: "washington-wizards" }] // Replace with actual team names
  },
  {
    id: 4,
    title: "nfl",
    value: "NFL",
    teams: [
      { name: "Arizona Cardinals", slug: "arizona-cardinals" },
      { name: "Atlanta Falcons", slug: "atlanta-falcons" },
      { name: "Baltimore Ravens", slug: "baltimore-ravens" },
      { name: "Buffalo Bills", slug: "buffalo-bills" },
      { name: "Carolina Panthers", slug: "carolina-panthers" },
      { name: "Chicago Bears", slug: "chicago-bears" },
      { name: "Cincinnati Bengals", slug: "cincinnati-bengals" },
      { name: "Cleveland Browns", slug: "cleveland-browns" },
      { name: "Dallas Cowboys", slug: "dallas-cowboys" },
      { name: "Denver Broncos", slug: "denver-broncos" },
      { name: "Detroit Lions", slug: "detroit-lions" },
      { name: "Green Bay Packers", slug: "green-bay-packers" },
      { name: "Houston Texans", slug: "houston-texans" },
      { name: "Indianapolis Colts", slug: "indianapolis-colts" },
      { name: "Jacksonville Jaguars", slug: "jacksonville-jaguars" },
      { name: "Kansas City Chiefs", slug: "kansas-city-chiefs" },
      { name: "Las Vegas Raiders", slug: "las-vegas-raiders" },
      { name: "Los Angeles Chargers", slug: "san-diego-chargers" },
      { name: "Los Angeles Rams", slug: "los-angeles-rams" },
      { name: "Miami Dolphins", slug: "miami-dolphins" },
      { name: "Minnesota Vikings", slug: "minnesota-vikings" },
      { name: "New England Patriots", slug: "new-england-patriots" },
      { name: "New Orleans Saints", slug: "new-orleans-saints" },
      { name: "New York Giants", slug: "new-york-giants" },
      { name: "New York Jets", slug: "new-york-jets" },
      { name: "Philadelphia Eagles", slug: "philadelphia-eagles" },
      { name: "Pittsburgh Steelers", slug: "pittsburgh-steelers" },
      { name: "San Francisco 49ers", slug: "san-francisco-49ers" },
      { name: "Seattle Seahawks", slug: "seattle-seahawks" },
      { name: "Tampa Bay Buccaneers", slug: "tampa-bay-buccaneers" },
      { name: "Tennessee Titans", slug: "tennessee-titans" },
      { name: "Washington Commanders", slug: "washington-commanders" }
    ]
  },
  // {
  //   id: 5,
  //   title: "concerts",
  //   value: "Concerts",
  //   teams: [],
  // },
  {
    id: 5,
    title: "razz",
    value: "Razzall",
    teams: [],
  },
  {
    id: 6,
    title: "Contact Us",
    value: "Contact Us",
    teams: [],
  },
];

interface FormData {
  firstname: string;
  lastname: string;
  given_name?:string;
  family_name?:string;
  email: string;
  addresses: {
    street_address: string;
    extended_address: string;
    locality: string;
    region: string;
    postal_code: string;
    country_code: string;
  }[];
  email_addresses: { address: string }[];
  phone_numbers: { country_code: string; number: string }[];
  role: string;
}
interface NavbarProps {
  className?: string; // Optional className prop
  loading?: boolean;
  setLoading?: (loading: boolean) => void;
}
interface CartState {
  viewtickets: [];
  ticketGroup: [];
}

const Navbar: React.FC<NavbarProps> = ({ className, setLoading }) => {
  //console.log("check user",users)
  const dispatch = useDispatch<AppDispatch>();
  const [cartItems, setCartItems] = useState(0)
  const [users, setUser] = useState<FormData | null>();
  const cartArray = useAppSelector((state) => state.cartReducer);
  const [scroll, setScroll] = useState(false);
  const [storedUser, setStoredUser] = useState<string | null>(null);
  const router = useRouter();
  const currentPath = usePathname();

  useEffect(() => {
    setLoading?.(false);
  }, [currentPath, setLoading]);

  useEffect(() => {
    setStoredUser(localStorage.getItem('users'));
  }, []);

  useEffect(() => {
    // Function to run whenever the 'users' item in localStorage changes
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'users') {
        setStoredUser(event.newValue);
      }
    };

    // Add event listener for 'storage' event
    window.addEventListener('storage', handleStorageChange);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  });

  useEffect(() => {
    // Retrieve user from local storage
    // const storedUser = localStorage.getItem('users');
    console.log("check user", storedUser);
    if (storedUser) {
      // Parse storedUser from JSON string to object
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      // Fetch cart from the database using the userId
      // Fetch cart from the database using the userId
      // Fetch cart from the database using the userId
      feathersClient.service('api/v1/cart').find({ query: { userId: parsedUser._id } })
        .then((existingCart: any) => {
          if (existingCart.data && existingCart.data.length > 0) {
            // Check if ticketGroup is an array
            // console.log(existingCart.data[0].cartItems[0].ticketGroup)
            if (Array.isArray(existingCart.data[0].cartItems[0].ticketGroup)) {
              // Update cartArray with existingCart
              setCartItems(existingCart.data[0].cartItems[0].ticketGroup.length)
            } else {
              console.error('ticketGroup is not an array:', existingCart.data[0].cartItems.ticketGroup);
            }
          }
        })
        .catch((error: any) => {
          console.error('Error fetching cart:', error);
        });
    } else {
      // Retrieve cart from local storage
      const storedCart = localStorage.getItem('cart');

      if (storedCart) {
        // Parse storedCart from JSON string to object
        const parsedCart = JSON.parse(storedCart);

        // Update cartArray with parsedCart
        setCartItems(parsedCart?.ticketGroup?.length)
      }
    }
  }, [cartArray, storedUser]);

  //   console.log("check user",userData);
  const logout = async() => {

    try{

      const logOutResponse = await feathersClient.logout();

      console.log('log out response', logOutResponse)

      if(logOutResponse){
        localStorage.removeItem("users");
        localStorage.removeItem("buyNow");
        localStorage.removeItem("cart");
        localStorage.removeItem("shippingAddress");
        const updatedCart: CartState = {
        viewtickets: [],
        ticketGroup: [],
        };
        dispatch(updateCart(updatedCart));
        setUser(null);
        setCartItems(0);
        router.push('/');
      }

    } catch(err) {
      console.log('error logging out',err)
    }

    // localStorage.removeItem("users");
    // localStorage.removeItem("feathers-authentication-token")
    // localStorage.removeItem("feathers-jwt")
    // localStorage.removeItem("buyNow")
    // localStorage.removeItem("cart")
    // const updatedCart: CartState = {
    //   viewtickets: [],
    //   ticketGroup: [],
    // };
    // //   dispatch(updateCart(updatedCart));
    // setUser(null);
    // setCartItems(0);
    // router.push('/');
  };

  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(Array(links.length).fill(false));
  const [loginDropdownOpen, setLoginDropdownOpen] = useState(false);

  const handleDropdownOpen = (index: number) => {
    setLoginDropdownOpen(false)
    setDropdownOpen((prev) => prev.map((_, i) => (i === index ? true : false)));
  };

  const handleDropdownClose = (index: number) => {
    setDropdownOpen((prev) => prev.map((_, i) => (i === index ? false : false)));
  };

  const handleLoginDropdownToggle = () => {
    setLoginDropdownOpen(!loginDropdownOpen);
  };

  const handleMouseLeave = (e: any) => {
    // if (!e.relatedTarget || !e.currentTarget.contains(e.relatedTarget)) {
    //   setLoginDropdownOpen(false);
    // }
    setLoginDropdownOpen(false);
  };
  const handleMouseEnter = () => {
    setLoginDropdownOpen(true);
  };

  function toUpperCase(str: string) {
    return str.toUpperCase();
  }

  // console.log("User client ----" , users);
  //console.log("Userclient ----", users.data?.profile);
  return (
    <nav className={`w-full flex sm:justify-around justify-between ${className} ${scroll ? "sticky z-50" : ""}`} onMouseLeave={handleMouseLeave}>
      {/* <IoMenuOutline className='text-3xl cursor-pointer md:hidden text-white' onClick={() => setOpen(!open)} /> */}
      <p className='text-base cursor-pointer md:hidden text-white ml-2' onClick={() => setOpen(!open)} >Menu</p>
      <div className='flex gap-[5rem] items-center justify-around md:gap-[3rem]'>
        <a href="/" title="Karma" className=''>
          <Image src="/Logo.png" alt="logo" width={126.4} height={64} className='w-[63.2px] h-[32px] md:w-[126px] md:h-[60px]' />
        </a>
      </div>
      {/* 
      <div className='my-auto md:flex min-h-fit bg-inherit z-[2] top-[-100%] md:px-0 px-5 hidden'>
        <ul className={`md:flex md:flex-row flex-col md:gap-[10px] lg:gap-[24px] md:text-white text-black font-bold hidden`}>
          {links.map(({ id, title, value, teams }, index) => (
            <li key={id} className='cursor-pointer relative' onMouseEnter={() => handleDropdownOpen(index)} onMouseLeave={() => handleDropdownClose(index)}>
              <Link href={title !== 'concerts' && title !== 'Contact Us' ? `/sports/${title}` : (`${title === 'Contact Us' ? '/contactus' : '#'}`)} className='hover:bg-white hover:text-[#42526D] p-1 rounded-md'>{value}</Link>
              {teams.length > 0 && dropdownOpen[index] && (
                <ul className="absolute bg-white min-w-max border-2  shadow-md p-2 rounded-md">
                  <div className="columns-3">
                    {teams.map((team:{ name: string; slug: string; }, teamIndex: number) => (
                      <Link href={title !== 'concerts' && title !== 'more' ? (`/top-teams/${team.slug}`) : (title == 'more' ? (`${team.slug}`): '')}><li key={teamIndex} className="font-medium hover:font-semibold text-sm text-[#42526D] lg:text-base hover:text-[#397BEA] hover:underline pr-2 pb-2 w-full">{typeof team === 'string' ? team : team.name}</li></Link>
                    ))}
                  </div>
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div> */}
      <NavigationMenu className='md:flex hidden'>
        <NavigationMenuList className='flex gap-[0px]'>
        <NavigationMenuItem>
            <Link href={`/faq`} >
              <NavigationMenuTrigger className={`${fredoka.className} font-semibold text-[#27276D]`} onClick={() => {
                if (currentPath !== '/faq') {
                  setLoading?.(true);
                }
              }}>
                FAQs
              </NavigationMenuTrigger>
            </Link>
          </NavigationMenuItem>
          <NavigationMenuItem>
            <Link href={`/sports/mlb`}>
              <NavigationMenuTrigger className={`${fredoka.className} font-semibold`} onClick={() => {
              if (currentPath !== '/sports/mlb') {
                setLoading?.(true);
              }
            }}>MLB</NavigationMenuTrigger>
            </Link>
            <NavigationMenuContent className='md:flex hidden'>
              <ul className="grid w-[400px] gap-1 p-2 md:w-[500px] md:grid-cols-3 lg:w-[600px] ">
                {links.find(link => link.title === 'mlb')?.teams.map((component) => (
                  <ListItem
                    key={component.name}
                    title={component.name}
                    href={`/top-teams/${component.slug}`}
                    onClick={() => setLoading?.(true)}
                  >
                    {/* {component.name} */}
                  </ListItem>
                ))}
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>
          <NavigationMenuItem>
            <Link href={`/sports/nhl`}>
              <NavigationMenuTrigger className={`${fredoka.className} font-semibold`} onClick={() => {
              if (currentPath !== '/sports/nhl') {
                setLoading?.(true);
              }
            }}>NHL</NavigationMenuTrigger>
            </Link>
            <NavigationMenuContent className='md:flex hidden'>
              <ul className="grid w-[400px] gap-1 p-2 md:w-[500px] md:grid-cols-3 lg:w-[600px] ">
                {links.find(link => link.title === 'nhl')?.teams.map((component) => (
                  <ListItem
                    key={component.name}
                    title={component.name}
                    href={`/top-teams/${component.slug}`}
                    onClick={() => setLoading?.(true)}
                  >

                    {/* {component.name} */}
                  </ListItem>
                ))}
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>
          <NavigationMenuItem>
            <Link href={`/sports/nba`}>
              <NavigationMenuTrigger className={`${fredoka.className} font-semibold`} onClick={() => {
              if (currentPath !== '/sports/nba') {
                setLoading?.(true);
              }
            }}>NBA</NavigationMenuTrigger>
            </Link>
            <NavigationMenuContent className='md:flex hidden'>
              <ul className="grid w-[400px] gap-1 p-2 md:w-[500px] md:grid-cols-3 lg:w-[600px] ">
                {links.find(link => link.title === 'nba')?.teams.map((component) => (
                  <ListItem
                    key={component.name}
                    title={component.name}
                    href={`/top-teams/${component.slug}`}
                    onClick={() => setLoading?.(true)}
                  >
                    {/* {component.name} */}
                  </ListItem>
                ))}
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>
          <NavigationMenuItem>
            <Link href={`/sports/nfl`}>
              <NavigationMenuTrigger className={`${fredoka.className} font-semibold`} onClick={() => {
              if (currentPath !== '/sports/nfl') {
                setLoading?.(true);
              }
            }}>NFL</NavigationMenuTrigger>
            </Link>
            <NavigationMenuContent className='md:flex hidden'>
              <ul className="grid w-[400px] gap-1 p-2 md:w-[500px] md:grid-cols-3 lg:w-[600px] ">
                {links.find(link => link.title === 'nfl')?.teams.map((component) => (
                  <ListItem
                    key={component.name}
                    title={component.name}
                    href={`/top-teams/${component.slug}`}
                    onClick={() => setLoading?.(true)}
                  >
                    {/* {component.name} */}
                  </ListItem>
                ))}
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>

          {/* <NavigationMenuItem>
            <Link href="/" legacyBehavior passHref>
              <NavigationMenuTrigger className={`${fredoka.className} font-semibold`} >
                Concerts
              </NavigationMenuTrigger>
            </Link>
          </NavigationMenuItem> */}
          {/* <NavigationMenuItem>
            <Link href="https://razilia.com"  legacyBehavior passHref>
              <NavigationMenuTrigger className={`${fredoka.className} font-semibold`} onClick={() => setLoading?.(true)}>
                RAZZ
              </NavigationMenuTrigger>
            </Link>
          </NavigationMenuItem> */}
           <NavigationMenuItem>
            <Link href="https://razilia.com" >
              <NavigationMenuTrigger className={`${fredoka.className} font-semibold`} onClick={() => {
                if (currentPath !== 'https://razilia.com') {
                  setLoading?.(true);
                }
              }}>
                RAZZ
              </NavigationMenuTrigger>
            </Link>
          </NavigationMenuItem>
          <NavigationMenuItem>
            <Link href={`/contactus`} >
              <NavigationMenuTrigger className={`${fredoka.className} font-semibold`} onClick={() => {
                if (currentPath !== '/contactus') {
                  setLoading?.(true);
                }
              }}>
                Contact Us
              </NavigationMenuTrigger>
            </Link>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>

      {open && (
        <div className='absolute flex bg-white min-h-[100vh] top-[0%] z-[2] left-0 p-5 w-full md:hidden'>
          <div className='flex flex-col w-full gap-[1rem]'>
            <div className='flex flex-row  justify-between'>
              <a href="/" title="Karma" className=''>
                <Image src='/Logo.png' alt="Logo" width={126.4} height={64} />
              </a>
              <RxCross2 className="text-2xl" onClick={() => setOpen(!open)} />
            </div>
            <ul className='flex flex-col gap-[24px] text-black font-semibold'>
              <Link className="text-red-500" href='/faq'>FAQs </Link>
              {links.map(({ id, title }) => (
                <li key={id} className='cursor-pointer text-[#27276D]'>
                  <Link href={title === 'Contact Us' ? '/contactus' : title === 'razz'?'https://razilia.com/': `/sports/${title}`}>{toUpperCase(title)}</Link>
                </li>
              ))}
              {
                !users && (
                  <>
                    <Link href='/login' className='cursor-pointer text-[#27276D]'>
                      Login
                    </Link>
                    <Link href='/signup' className='cursor-pointer text-[#27276D]'>
                      Signup
                    </Link>
                  </>
                )
              }
              {/* <Link href='/login' className='cursor-pointer'>
                Login
              </Link>
              <Link href='/signup' className='cursor-pointer'>
                Signup
              </Link> */}
            </ul>
          </div>
        </div>
      )}

      <div className='gap-6 relative flex items-center '>
        <Link href="/cart">
          <div className='flex items-center gap-3 relative'>
            <HiShoppingCart className='text-white w-5 h-5' />
            <h2 className='text-white text-[11px] bg-red-600 rounded-lg px-1 absolute mb-5 ml-3 '>
              {cartItems > 0 && cartItems < 10 ? `0${cartItems}` : cartItems}
            </h2>

          </div>
        </Link>


        <div className='relative'>
          <div className='flex items-center' onMouseEnter={handleMouseEnter} onClick={handleLoginDropdownToggle}>
            {/* <button className='text-white max-sm:text-sm'>
              {users ? `${users?.name}` : (
                <div className='max-[400px]:text-[10px] text-bold md:flex'>
                  <div className='hidden md:flex'>Login</div>
                  <div className='hidden md:flex'>/SignUp</div>
                </div>
              )}
            </button> */}
            <button className="text-white max-sm:text-sm">
              {users ? (
                // <span className={`inline-block w-[100px] sm:w-auto break-words ${fredoka.className}`}>{users?.firstname + ' ' + users?.lastname}</span>
                <span
                  className={`inline-block w-[100px] sm:w-auto break-words ${fredoka.className}`}
                >
                  {users?.firstname && users?.lastname
                    ? `${users.firstname} ${users.lastname}`
                    : users?.firstname ? users?.firstname : users?.email}
                </span>
              ) : (
                <div
                  className={`max-[400px]:text-[10px] text-bold md:flex ${fredoka.className}`}
                >
                  <div className="hidden md:flex">Login</div>
                  <div className="hidden md:flex">/SignUp</div>
                </div>
              )}
            </button>
            <FaChevronDown className='m-auto md:flex hidden w-4 h-5 text-white' />
          </div>
          {loginDropdownOpen && (
            <ul className="absolute z-50 w-24 text-sm top-full bg-white mt-[0px] md:mt-[-1px] md:ml-[7px] shadow-md p-2 rounded-md" onMouseEnter={handleMouseEnter}>
              {users ? (
                <>
                  <li className={`text-[#42526D] hover:text-[#397BEA] ${fredoka.className}`}>
                    <Link href="/profile">Profile</Link>
                  </li>
                  <li className={`text-[#42526D] hover:text-[#397BEA] ${fredoka.className}`}>
                    <button onClick={logout}>Logout</button>
                  </li>
                  <li className={`text-[#42526D] hover:text-[#397BEA] ${fredoka.className}`}>
                    {
                      users?.role === 'SUPERADMIN' ? <Link href="/dashboard">Dashboard</Link> : <Link href="/my-tickets">My Tickets</Link>
                    }
                  </li>
                </>
              ) : (
                <>
                  <li className={`text-[#42526D] hover:text-[#397BEA] ${fredoka.className}`}>
                    <Link href="/login">Login</Link>
                  </li>
                  <li className={`text-[#42526D] hover:text-[#397BEA] ${fredoka.className}`}>
                    <Link href="/signup">SignUp</Link>
                  </li>
                </>
              )}
            </ul>
          )}
        </div>




      </div>
    </nav>
  );
};

export default Navbar;
const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-1 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none cursor-pointer hover:text-blue-500 hover:text-underline">{title}</div>
          <p className="line-clamp-3 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})
ListItem.displayName = "ListItem"